// Import dependencies
import React from "react";
import { getFirebase } from "../api/firebase";
import {navigate} from "gatsby";
import {getCartQuote} from "../util/cart";
import { connect } from "react-redux";


/*
    Error during memebership purchase
*/
  class RefealCode extends React.Component {
    constructor(props) {
      super(props);
      const {
        location: { state },
      } = this.props;
    }

    componentDidMount() {
      if (this.props["*"] && getCartQuote(this.props.cart, this.props.generalCart) > 15) {
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");

        Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore().collection("refeal_codes").get().then((querySnapshot) => {
            let checkCodePromo = false;
            querySnapshot.forEach((doc) => {
              if (doc.data().code == this.props.params["*"]) {
                checkCodePromo = true;
              }
            });
            if (checkCodePromo) {
              navigate("/step-1",{
                state: {
                  codePromoPrev: {code: this.props.params["*"], value: 5}
                }
              });
          } else {
              navigate("/404");
            }
        })
      });

      } else {
      navigate("/", {});
      }
    }





    render() {
      return null;
    }
}

// Connect redux to component
const mapStateToProps = state => ({
    cart: state.cart,
    generalCart: state.generalCart,
});

export default connect(mapStateToProps)(RefealCode);
